import React, { useState,useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';

const PopularSearchDynamic = (props) => {
    //Get values from refine search
    const [ptype, setPtype] = useState(false)
    const [searchbed, setsearchbed] = useState(false)

    var searchaction = '';
    var URL = '';
    var ptypeURL = '';
    var bedURL = '';
    var bedtypeText= '';
    var area = 'Dubai';
    var typeText = 'Property';
    var searcharea = '';
    var searchareaText= '';
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }  

    if(props.searchtype == 'sales') {
        searchaction = 'for Sale'
        URL = '/properties-for-sale/'
    }
    if(props.searchtype == 'lettings') {
        searchaction = 'for Rent'
        URL = '/properties-for-rent/'
    }

    if(props.searchBedroomfield) {
        if (props.searchBedroomfield === '0') {
            bedtypeText = 'Studio Property'
            bedURL = '/'+ 'studio'
        } else {
            bedtypeText = props.searchBedroomfield+' Bedroom '+typeText
            bedURL = '/'+props.searchBedroomfield+'-and-more-bedrooms'
        }
    }

    if(props.searchPtype) {
        ptypeURL   = '/type-'+props.searchPtype
        typeText     = capitalizeFirstLetter(props.searchPtype)
      }

    if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
        if(typeof props.Searcharea.areas == "string") {
            searcharea = props.Searcharea.areas
            searchareaText = props.Searcharea.areas.replaceAll("-", " ")
        } else {
            searcharea = props.Searcharea.areas[0]
            searchareaText = props.Searcharea.areas[0].replaceAll("-", " ")
        }
    } else {
        searchareaText = area;
    }
   
    return (
        <div className="popular-search">
            <Row>
                {props.searchBedroomfield ?
                    <Col md={6} lg={3} className="properties-info">
                        <ul>
                            <li><Link to={`${URL}in-dubai${bedURL}/`}>{bedtypeText} {searchaction} in Dubai</Link></li>
                            <li><Link to={`${URL}in-arabian-ranches${bedURL}/`}>{bedtypeText} {searchaction} in Arabian Ranches</Link></li>
                            <li><Link to={`${URL}in-palm-jumeirah${bedURL}/`}>{bedtypeText} {searchaction} in Palm Jumeirah</Link></li>
                            <li><Link to={`${URL}in-springs${bedURL}/`}>{bedtypeText} {searchaction} in Chesterfield</Link></li>
                        </ul>
                    </Col>
                    :<Col md={6} lg={3} className="properties-info">
                        <ul>
                            <li><Link to={`${URL}in-dubai${ptypeURL}/`}>{typeText} {searchaction} in Dubai</Link></li>
                            <li><Link to={`${URL}in-arabian-ranches${ptypeURL}/`}>{typeText} {searchaction} in Arabian Ranches</Link></li>
                            <li><Link to={`${URL}in-palm-jumeirah${ptypeURL}/`}>{typeText} {searchaction} in Palm Jumeirah</Link></li>
                            <li><Link to={`${URL}in-springs${ptypeURL}/`}>{typeText} {searchaction} in The Springs</Link></li>
                        </ul>
                    </Col>
                }

                {(searcharea) ?
                    <Col md={6} lg={3} className="properties-info">
                        {props.searchBedroomfield || props.searchPtype ?
                            <ul>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/1-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "1 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/2-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "2 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/3-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "3 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/4-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "4 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                            </ul> :
                            <ul>
                                <li><Link to={`${URL}in-${searcharea}/type-villa/`}>Villas {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                                <li><Link to={`${URL}in-${searcharea}/type-apartment/`}>Apartments {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                                <li><Link to={`${URL}in-${searcharea}/type-townhouse/`}>Townhouse {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                                <li><Link to={`${URL}in-${searcharea}/type-penthouse/`}>Pentohouses {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                            </ul>
                        }
                    </Col> :
                    <Col md={6} lg={3} className="properties-info">
                        {(props.searchBedroomfield && (props.searchPtype || props.price)) ?
                            <ul>
                                <li><Link to={`${URL}in-dubai${ptypeURL}/`}>{props.searchPtype ? typeText : 'Property'} {searchaction} in Dubai</Link></li>
                                <li><Link to={`${URL}in-arabian-ranches${ptypeURL}/`}>{props.searchPtype ? typeText : 'Property'} {searchaction} in Arabian Ranches</Link></li>
                                <li><Link to={`${URL}in-palm-jumeirah${ptypeURL}/`}>{props.searchPtype ? typeText : 'Property'} {searchaction} in Palm Jumeirah</Link></li>
                                <li><Link to={`${URL}in-springs${ptypeURL}/`}>{props.searchPtype ? typeText : 'Property'} {searchaction} in The Springs</Link></li>
                            </ul> 
                        :
                        (!props.searchBedroomfield && (props.searchPtype || props.price)) ?
                            <ul>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/1-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "1 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {area}</Link></li>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/2-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "2 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {area}</Link></li>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/3-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "3 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {area}</Link></li>
                                <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}${ptypeURL}/4-and-more-bedrooms/`}>{props.searchBedroomfield ? "" : "4 Bedroom"} {props.searchPtype ? typeText : 'Property'} {searchaction} in {area}</Link></li>
                            </ul>
                        :
                            <ul>
                                <li><Link to={`${URL}in-${area.toLowerCase()}/type-villa/`}>Villas {searchaction} in {area}</Link></li>
                                <li><Link to={`${URL}in-${area.toLowerCase()}/type-apartment/`}>Apartments {searchaction} in {area}</Link></li>
                                <li><Link to={`${URL}in-${area.toLowerCase()}/type-townhouse/`}>Townhouse {searchaction} in {area}</Link></li>
                                <li><Link to={`${URL}in-${area.toLowerCase()}/type-penthouse/`}>Pentohouses {searchaction} in {area}</Link></li>
                            </ul>
                        }
                    </Col>  
                }    

                {(props.searchBedroomfield || props.searchPtype || props.price) ?
                    <Col md={6} lg={3} className="properties-info">
                        <ul>
                            <li><Link to={`${URL}in-${area.toLowerCase()}/type-villa/`}>Villas {searchaction} in {area}</Link></li>
                            <li><Link to={`${URL}in-${area.toLowerCase()}/type-apartment/`}>Apartments {searchaction} in {area}</Link></li>
                            <li><Link to={`${URL}in-${area.toLowerCase()}/type-townhouse/`}>Townhouse {searchaction} in {area}</Link></li>
                            <li><Link to={`${URL}in-${area.toLowerCase()}/type-penthouse/`}>Pentohouses {searchaction} in {area}</Link></li>
                        </ul>
                    </Col> : 
                    <Col md={6} lg={3} className="properties-info">
                        <ul>
                            <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/1-and-more-bedrooms/`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                            <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/2-and-more-bedrooms/`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                            <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/3-and-more-bedrooms/`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                            <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/4-and-more-bedrooms/`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        </ul>
                    </Col>
                }    

                <Col md={6} lg={3} className="properties-info">
                    <ul>
                        <li><Link to="/living-in-dubai/">{capitalizeFirstLetter(searchareaText)} Area Guide</Link></li>
                        <li><Link to="/about-espace/meet-the-team/">Estate Agents in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to="/property-management-dubai/">Property Management in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to="/sell-dubai-property/property-valuation/">Property Valuation</Link></li>
                    </ul>
                </Col>  
            </Row>
        </div>
    )
}

export default PopularSearchDynamic;
