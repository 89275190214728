import React from "react";

const AreaList = ({areas, removeArea, className=''}) => {
    if (!areas || areas.length < 1)
        return <></>

    function removeAreaFun(e, area) {
        e.preventDefault();
        removeArea(area)
    }
    // function removeAreaAllFun(e, area) {
    //     e.preventDefault();
    //     removeArea('', true)
    // }

    return (
        <>
        {/*<Container>
            <Row>
                <Col>*/}
                    <div className={`area-list ${className}`}>
                        <div className="section-tags-wrapper">
                            <ul className="list-tags">
                                {areas.map((item, key) => {
                                    return(
                                        <>
                                            <li>
                                                <div className="tag">
                                                    <div className="tag-text"><span>{item.name}</span></div>
                                                    <a href="javascript:;" className="tag-clear link-tag-clear pointer-events-all cursor-pointer" onClick={(e) => removeAreaFun(e, item.slug)}>
                                                        <i className="icon-close"></i>
                                                    </a>
                                                </div>
                                            </li>
                                        </>
                                    )
                                })}
                                {/* <li className="pointer-events-all clear-btn-area">
                                    <div className="tag">
                                        <div className="tag-text"><span> </span></div>
                                        <a className="tag-clear link-clear-all m-0" onClick={(e) => removeAreaAllFun(e, '')}>
                                            <i className="icon-close"></i>
                                        </a>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                {/*</Col>
            </Row>
        </Container> */}
        </>
    )
}

export default AreaList;
