import React, { useState } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';

const PopularSearchDynamicForDetailpage = (props) => {
    var searchaction = '';
    var URL = '';
    var ptypeURL = '';
    var bedURL = '';
    var bedtypeText= '';
    var area = 'Dubai';
    var typeText = 'Property';
    var searcharea = '';
    var searchareaText= '';
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }  

    if(props.searchtype == 'sales') {
        searchaction = 'for Sale'
        URL = '/properties-for-sale/'
    }
    if(props.searchtype == 'rentals' || props.searchtype == 'lettings' ) {
        searchaction = 'for Rent'
        URL = '/properties-for-rent/'
    }

    if(props.searchBedroomfield) {
        if (props.searchBedroomfield === '0') {
            bedtypeText = 'Studio Property'
            bedURL = '/'+ 'studio'
        } else {
            bedtypeText = props.searchBedroomfield+' Bedroom '+typeText
            bedURL = '/'+props.searchBedroomfield+'-and-more-bedrooms'
        }
    }

    if(props.searchPtype) {
        ptypeURL   = '/type-'+props.searchPtype
        typeText     = capitalizeFirstLetter(props.searchPtype)
      }

    if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
        if(typeof props.Searcharea.areas == "string") {
            searcharea = props.Searcharea.areas
            searchareaText = props.Searcharea.areas.replaceAll("-", " ")
        } else {
            searcharea = props.Searcharea.areas[0]
            searchareaText = props.Searcharea.areas[0].replaceAll("-", " ")
        }
    } else {
        searchareaText = area;
    }
   
    return (
        <div className="popular-search">
            <Row>
                <Col md={6} lg={3} className="properties-info">
                    <ul>
                        <li><Link to={`${URL}in-dubai${ptypeURL}/`}>{typeText} {searchaction} in Dubai</Link></li>
                        <li><Link to={`${URL}in-arabian-ranches${ptypeURL}/`}>{typeText} {searchaction} in Arabian Ranches</Link></li>
                        <li><Link to={`${URL}in-palm-jumeirah${ptypeURL}/`}>{typeText} {searchaction} in Palm Jumeirah</Link></li>
                        <li><Link to={`${URL}in-springs${ptypeURL}/`}>{typeText} {searchaction} in The Springs</Link></li>
                    </ul>
                </Col>
                
                <Col md={6} lg={3} className="properties-info">
                    <ul>
                        <li><Link to={`${URL}in-${area.toLowerCase()}/type-villa/`}>Villas {searchaction} in {area}</Link></li>
                        <li><Link to={`${URL}in-${area.toLowerCase()}/type-apartment/`}>Apartments {searchaction} in {area}</Link></li>
                        <li><Link to={`${URL}in-${area.toLowerCase()}/type-townhouse/`}>Townhouse {searchaction} in {area}</Link></li>
                        <li><Link to={`${URL}in-${area.toLowerCase()}/type-penthouse/`}>Pentohouses {searchaction} in {area}</Link></li>
                    </ul>   
                </Col>  
                
                <Col md={6} lg={3} className="properties-info">
                    <ul>
                        <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/1-and-more-bedrooms/`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/2-and-more-bedrooms/`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/3-and-more-bedrooms/`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to={`${URL}in-${searcharea || area.toLowerCase()}/4-and-more-bedrooms/`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
                    </ul>
                </Col>
                
                <Col md={6} lg={3} className="properties-info">
                    <ul>
                        <li><Link to="/living-in-dubai/">{capitalizeFirstLetter(searchareaText)} Area Guide</Link></li>
                        <li><Link to="/about-espace/meet-the-team/">Estate Agents in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to="/property-management-dubai/">Property Management in {capitalizeFirstLetter(searchareaText)}</Link></li>
                        <li><Link to="/sell-dubai-property/property-valuation/">Property Valuation</Link></li>
                    </ul>
                </Col>  
            </Row>
        </div>
    )
}

export default PopularSearchDynamicForDetailpage;
