import React from "react";
import HTMLReactParser from 'html-react-parser';

const PopularSearch = (props) => {
    return (
        <React.Fragment>
            <div className="popular-search">
                {HTMLReactParser(props?.data)}
            </div>
        </React.Fragment>
    )
}

export default PopularSearch;