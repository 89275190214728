import { Link } from "gatsby";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Menudata = (props) => {
    const navigate = (e) => {
        if(props.item.submenu.length > 0){
            e.stopPropagation()
            props.handlerOpenMenu(props.index)
        }
    }
    return (
        <li
            className={
                props.item.submenu || props.item.isActive
                ? "active card-header"
                : "card-header"
            }
            onClick={navigate}
            
        >
            <div className="w-100 position-relative">
                <Link style={{width: "fit-content"}} to={`/${props.item.url}`}>
                    <div className={props.active ? "active-menu" : ""}>{props.item.name}</div>
                </Link>
                {props.item.submenu && props.item.isActive ? (
                    <i className="icon-minus-white" onClick={navigate}></i>
                ) : (
                    props.item.submenu.length > 0 && <i className="icon-plus-white" onClick={navigate}></i>
                )}
            </div>
            {props.item.submenu && props.item.isActive && ( 
                    <ul className="submenu-list">
                        {props.item.submenu.map((item, i) => (
                            <li
                                className={
                                    item.submenu && item.isActive
                                    ? "active card-header"
                                    : "card-header"
                                }
                                key={i}
                                onClick={(e) => props.handlerOpenSubMenu(e, props.index, i, "submenu")}
                            >
                                <div className="w-100 position-relative">
                                    <Link to={`/${item.url}`}>{item.name}</Link>
                                    {item.submenu.length > 0 && item.isActive ? (
                                        <i className="icon-minus-white"></i>
                                    ) : (
                                        item.submenu.length > 0 && <i className="icon-plus-white"></i>
                                    )}
                                </div>
                                {item.submenu.length > 0 && item.isActive && (
                                    <ul className="submenu-list">
                                        {item.submenu.map((sub_item, k) => (
                                            <li onClick={(e) => e.stopPropagation()} className="menu-accordian" key={k}>
                                                <Link to={`/${sub_item.url}`}>{sub_item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul> 
            )}
        </li>
    );
};

export default Menudata;
