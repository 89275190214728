import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useContext, useState, useEffect } from "react";
import {
    Modal,
    Container,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
// import AccordionContext from "react-bootstrap/AccordionContext";
// import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import espaceLogo from "../../images/footer-espace-logo.svg"
import PopularSearch from "./PopularSearch.js"
import "./Footer.scss";
import SubscribeHot10Page from "../forms/subscribe-hot-10";
import PopularSearchDynamic from "./PopularSearchForResults";
import PopularSearchDynamicForDetailpage from "./PopularSearchForDetails";
import ScrollAnimation from "react-animate-on-scroll";

// images

// Style
// function ContextAwareToggle({ children, eventKey, callback }) {
//     const currentEventKey = useContext(AccordionContext);
//     const decoratedOnClick = useAccordionToggle(eventKey,() => callback && callback(eventKey));
//     const isCurrentEventKey = currentEventKey === eventKey;
//     return (
//         <div
//             className={
//                 isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
//             }
//             onClick={decoratedOnClick}
//         >
//             {children}
//             <span className={isCurrentEventKey ? "d-none" : "d-block"}>+</span>
//             <span className={isCurrentEventKey ? "d-block " : "d-none"}>-</span>
//         </div>
//     );
// };

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const Footer = (props) => {
    var d = new Date();
    var n = d.getFullYear();
    const [showSearch, setShowSearch] = useState(false);
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const displaySearch = ()=> {
        setShowSearch(!showSearch)
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                siteConfig {
                    Facebook_Link
                    Instagram_Link
                    Linkedin_Link
                    Twitter_Link
                    Youtube_Link
                    Popular_Static
                    Popular_Buy
                    Popular_Rent
                    Popular_About
                }
            }
        }
    `)

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateEmail(email)) {
            handleShow();
        } else {
            setEmailErr("Enter a valid Email")
        }
    }

    return (
        <React.Fragment>
            <footer className="footer">
                <Container>
                    <Row>   
                        <Col xs={12} className="">
                            <div className="popular-search-list">
                                <h4 onClick={e => displaySearch()} className="cursor-pointer">
                                    Popular Searches <i className={showSearch ? "icon-minus-white" : "icon-plus-white"}></i>
                                </h4>             
                                {showSearch && <div>
                                    <ScrollAnimation animateIn="fadeInUp" className={showSearch ? "" : "d-none"}>
                                        {props.popularSearch && <PopularSearch 
                                            data={data.glstrapi.siteConfig[props.popularSearch]}
                                        />}
                                        {props.popularSearchForResults && 
                                            <PopularSearchDynamic
                                                {...props}
                                            />
                                        }
                                        {props.popularSearchDetails && 
                                            <PopularSearchDynamicForDetailpage
                                                {...props}
                                            />
                                        }
                                    </ScrollAnimation>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                    <Row>   
                        <Col xs={12} >
                            <div className="footer-detail d-xl-flex justify-content-between">
                                <Form className="banner-form" onSubmit={handleSubmit}>
                                    <h2 className="main-title">Stay <span> in the loop </span></h2>
                                    <Form.Group className="mb-1 position-relative">
                                        <Form.Control type="email" placeholder="Email Address" value={email} onChange={(e) => {setEmail(e.target.value); setEmailErr("")}} />
                                        <Button className="text-uppercase" variant="primary" type="submit">
                                            subscribe
                                        </Button>
                                        {emailErr !== "" && <div className="invalid-feedback position-absolute d-block mt-3 text-end">
                                            {emailErr}
                                        </div>}
                                    </Form.Group>
                                    <p className="subs-desc mt-5">By clicking Subscribe, you agree to our <br className="d-md-none" />
                                        <Link to="/terms-of-use/">Terms&nbsp;</Link>
                                        and 
                                        <Link to="/privacy-policy/">&nbsp;Privacy Policy.</Link>
                                    </p>
                                </Form>
                                <div className="social-info">
                                    <h2 className="main-title">Stay <span> connected </span></h2>
                                    <ul className="ml-0 social-list d-flex align-items-center">
                                        <li><a target="_blank" href={data.glstrapi.siteConfig.Twitter_Link}><i className="icon-twitter"></i></a></li>
                                        <li><a target="_blank" href={data.glstrapi.siteConfig.Facebook_Link}><i className="icon-facebook"></i></a></li>
                                        <li><a target="_blank" href={data.glstrapi.siteConfig.Youtube_Link}><i className="icon-youtube"></i></a></li>
                                        <li><a target="_blank" href={data.glstrapi.siteConfig.Instagram_Link}><i className="icon-insta"></i></a></li>
                                        <li><a target="_blank" href={data.glstrapi.siteConfig.Linkedin_Link}><i className="icon-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <Link to="/" className="footer-logo d-none d-md-inline-block"><img src={espaceLogo} alt="Logo - Espace"/></Link>
                            <p className="subs-desc copy-rgt">
                            <Link to="/terms-of-use/">Terms &amp; Conditions </Link> / <Link to="/privacy-policy/"> Privacy Policy </Link> / <Link to="/sitemap/">Sitemap</Link> <br /> 
                            &copy; {n} Espace. Site by <a className="starberry-icon" href="https://starberry.tv" target="_blank"><i className="icon-starBerry"/></a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <Modal show={show} onHide={handleClose} centered className="contact-form-sec">
                <div className="contact-form subsribe-form hide-form">
                    <Modal.Header closeButton>
                        <Modal.Title>Subscribe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SubscribeHot10Page email={email}/>
                    </Modal.Body>
                </div>
            </Modal>
        </React.Fragment>
        
    )
}
export default Footer;
