import React, { useState, useEffect } from "react"
import { isEmpty, find } from "lodash"
import { Container, Row, Col, Tabs, Tab, Form } from "react-bootstrap"
import $ from "jquery"
import { navigate } from "@reach/router"
// import SearchInputBox from '../predictive-search/search-inputbox';
import SearchInputBox from "../predictive-search/search-multiareas"
import LocalSiteSearch from "./LocalSiteSearch.js"
import AreaListBox from "../SearchResult/FilterBlock/AreaList"
import "./assets/styles/_index.scss"
import { createSearchUrlFromParams } from "../SearchResult/FilterBlock/functions"

const SearchOverlay = props => {
  const [areasArr, setAreasArr] = useState([])
  const [areasList, setAreasList] = useState([])

  const handleMultiAreaFun = val => {
    let areas = []
    // find
    if (isEmpty(find(areasArr, val))) {
      areas = [...areasArr]
      areas.push(val)
      setAreasArr(areas)
    }
  }

  const removeArea = (area, clear = false) => {
    if (!clear) {
      let prevAreas = areasArr.filter(x => x.slug !== area)
      setAreasArr(prevAreas)
    } else {
      setAreasArr([])
    }
  }

  function handleSubmit(e, search_type) {
    e.preventDefault()
    let customValue = []
    if (areasArr) {
      areasArr.map(a => {
        if (a) {
          customValue.push(a.slug)
        }
      })
    }

    let params = {
      areas: customValue,
      search_type: search_type,
    }
    navigate(createSearchUrlFromParams(params))
    setTimeout(function () {
      $("body.modal-open").removeClass("modal-open")
      $(".search-overlay").removeClass("active-search")
      $(".search-modal").remove()
    }, 1000)

    //used below function to fix overlap header menus at  property listing page
    setTimeout(() => {
      const closeModal = document.getElementById("sticky-results")
      if (closeModal?.style) {
        closeModal.style.zIndex = "9"
      }
      const headerComponent = document.getElementsByClassName("header")
      if (headerComponent[0]?.style) {
        headerComponent[0].style.zIndex = "7"
      }
    }, 1500)
  }

  function closeSearchOverlay(e) {
    e.preventDefault()
    $("body").removeClass("modal-open").find(".modal-backdrop").remove()
    $(".search-overlay").removeClass("active-search")

    //used below function to fix overlap header menus at  property listing page
    setTimeout(() => {
      const closeModal = document.getElementById("sticky-results")
      if (closeModal?.style) {
        closeModal.style.zIndex = "9"
      }
      const headerComponent = document.getElementsByClassName("header")
      if (headerComponent[0]?.style) {
        headerComponent[0].style.zIndex = "7"
      }
    }, 500)
  }

  return (
    <>
      <div className="search-overlay search-overlay-hidden">
        <a onClick={e => closeSearchOverlay(e)}>
          <i className="icon-close"></i>
        </a>
        <Container className="property-search-overlay_vertical_align">
          <Row className="justify-content-center">
            <Col lg={10}>
              <Tabs
                defaultActiveKey="sitesearch"
                id="uncontrolled-tab-example"
                className="search-tab"
              >
                <Tab
                  eventKey="sitesearch"
                  className="search-overlay-input"
                  title="Site Search"
                >
                  {/*<Form.Control type="text" className="" placeholder="Search the website" /> */}
                  <LocalSiteSearch />
                </Tab>
                <Tab eventKey="propertysearch" title="Property Search">
                  <div className="input-group mb-3 property-search-overlay">
                    <div className="input-group-prepend property-search-width search-overlay-input property-search_overlay property_search_overlay">
                      <SearchInputBox
                        setAreasList={setAreasList}
                        handleMultiAreaFun={handleMultiAreaFun}
                      />
                    </div>
                    <div className="search-overlay-btn d-none d-md-flex align-items-center justify-content-between">
                      <a
                        className="text-uppercase sales_overlay_btn"
                        onClick={e => handleSubmit(e, "sales")}
                      >
                        buy
                      </a>
                      <a
                        className="text-uppercase rent_overlay_btn"
                        onClick={e => handleSubmit(e, "lettings")}
                      >
                        rent
                      </a>
                    </div>
                    <AreaListBox
                      areas={areasArr}
                      removeArea={removeArea}
                      className={`area-list-overlay`}
                    />
                    <div className="change-type d-flex d-md-none align-items-center justify-content-between">
                      <a
                        className="text-uppercase sales_overlay_btn mobile-search-overlay-btn"
                        onClick={e => handleSubmit(e, "sales")}
                      >
                        buy
                      </a>
                      <a
                        className="text-uppercase rent_overlay_btn mobile-search-overlay-btn"
                        onClick={e => handleSubmit(e, "lettings")}
                      >
                        rent
                      </a>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SearchOverlay
