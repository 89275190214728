import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
// import { ReCaptcha } from 'react-recaptcha-v3'



const RecaptBox = ({fieldClass, captRef, /*handleonError, handleonExpire,*/ handleonVerify}) => (
    <div className="form-group recapta-group">
        <div className={fieldClass}>
            <ReCAPTCHA
                ref={captRef}
                size="invisible"
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                // sitekey="6LeiPsAaAAAAAFAElJEYFpl26WdA_776amgrSe5w"
                onChange={handleonVerify}
            />
            {/* <ReCaptcha
                ref={captRef}
                sitekey="6LeiPsAaAAAAAFAElJEYFpl26WdA\_776amgrSe5w"
                action="submit"
                verifyCallback={handleonVerify}
            /> */}

        </div>
    </div>
);

export default RecaptBox