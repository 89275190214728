import moment from 'moment';
import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputField = ({ fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, onFocus, min, startDate, setStartDate }) => {
    React.useEffect(() => {
        var input = document.getElementById('validationtelephone');
        if (input) {
            input.onkeydown = function (e) {
                let k = e.which;
                /* numeric inputs can come from the keypad or the numeric row at the top */
                if ((k < 48 || k > 57) && (k < 96 || k > 105) && (k != 8 && k != 9)) {
                    e.preventDefault();
                    return false;
                }
                if(e.target.value.length > 20 && (k != 8) && (k != 46)) {
                    e.preventDefault()
                    return false;
                }
            };
        }
        var input1 = document.getElementById('validationtelephone1');
        if (input1) {
            input1.onkeydown = function (e) {
                let k = e.which;
                /* numeric inputs can come from the keypad or the numeric row at the top */
                if ((k < 48 || k > 57) && (k < 96 || k > 105) && (k != 8 && k != 9)) {
                    e.preventDefault();
                    return false;
                }
                if(e.target.value.length > 20 && (k != 8) && (k != 46)) {
                    e.preventDefault()
                    return false;
                }
            };
        }
        var nameInput = document.getElementById('validationname');
        if (nameInput) {
            nameInput.onkeydown = function (e) {
                let k = e.which;
                if ((k > 48 && k < 57) && (k != 8)) {
                    e.preventDefault();
                    return false;
                }
            };
        }
        var nameInput1 = document.getElementById('validationname1');
        if (nameInput1) {
            nameInput1.onkeydown = function (e) {
                let k = e.which;
                if ((k > 48 && k < 57) && (k != 8)) {
                    e.preventDefault();
                    return false;
                }
            };
        }
    }, []);
    return(
        <Form.Group as={Col} md={grpmd} controlId={"validation" + name}>
            {label ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>: ''}
            {(type == "date" || type == "time") ? 
                <DatePicker
                    selected={startDate}
                    onChange={v => {
                        setStartDate(v)
                        handlechange({ target: { name: name, value: type === "date" ? moment(v).format("YYYY-MM-DD") : moment(v).format("HH:MM") }})
                    }}
                    showTimeSelect={type == "time"}
                    showTimeSelectOnly={type == "time"}
                    minDate={new Date()}
                    placeholderText={placeholder}
                    customInput={<input className="form-control"/>}
                    dateFormat={type === "date" ? "dd/MM/yyyy" : "hh:mm a"}
                    timeFormat="hh:mm aa"
                    className={fieldClass}
                    required={required}
                    autocomplete="off"
                    name={name}
                    autoComplete={'' + Math.random()}                
                    // value={startDate}
                />
                : 
                <Form.Control
                    className={fieldClass}
                    required={required}
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={handlechange}
                    pattern={pattern}
                    onFocus={onFocus}
                    min={min}
                />
            }
        </Form.Group>
    );
}

export default InputField;