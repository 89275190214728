import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { Navbar, Nav, Container, Row, Col, Card, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
// Images
import BrandLogo from "../../images/espace-logo.svg"
import BrandWhiteLogo from "../../images/espace-logo-white.svg"
import $ from "jquery"
import SearchOverlay from "../SearchOverlay/SearchOverlay"
import "./Header.scss"
import MenuData from "./MenuData"
import { GetURL, removePlus } from "../utils"
import { isIOS, isSafari } from "react-device-detect"
import { useLocation } from "@reach/router"
// import SearchInputBox from "../predictive-search/search-inputbox";

const Header = props => {
  const [showDropDown, setDropDown] = useState(false)
  const [query, setQuery] = React.useState("")
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        menuBurgers(where: { Publish: true }, sort: "Sort") {
          _id
          Parent_Label
          parent_link {
            id
            URL
          }
          Sub_Menus {
            id
            Label
            Link {
              id
              URL
            }
            Add_Sub_Menus {
              id
              Sub_Menu_Label
              Sub_Menu_Link {
                id
                URL
              }
            }
          }
        }
        siteConfig {
          Office_Phone
          Office_Whatsapp
        }
      }
    }
  `)

  const menus = data.glstrapi.menuBurgers.map((m, k) => {
    let menu = {
      index: k,
      name: m.Parent_Label,
      url:
        m.parent_link != null ? GetURL(m.parent_link.id, m.Parent_Label) : null,
      submenu: m.Sub_Menus.map(s => ({
        name: s.Label,
        isActive: false,
        url: s.Link != null ? GetURL(s.Link.id, s.Label) : null,
        submenu: s.Add_Sub_Menus.map(asb => ({
          name: asb.Sub_Menu_Label,
          url:
            asb.Sub_Menu_Link != null
              ? GetURL(asb.Sub_Menu_Link.id, asb.Sub_Menu_Label)
              : null,
        })),
      })),
      isActive: false,
    }
    return menu
  })

  const [show, setShow] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [state, setState] = useState(menus)

  const location = useLocation()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1)
    })
    $("a[href='#']").removeAttr("href")
  }, [])

  const showMenu = e => {
    e.preventDefault()
    let el = document.getElementById("sticky-results")
    if (el != null) {
      el.classList.remove("sticky-top")
    }
    setShow(!show)
  }

  const handlerOpenMenu = index => {
    let newState = [...state]
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    let isActive = a[index].isActive
    a[index].isActive = !isActive
    setState(a)
  }

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation()
    let newState = [...state]
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    newState[index].submenu = a
    let isActive = newState[index].submenu[i].isActive
    newState[index].submenu[i].isActive = !isActive
    setState(newState)
  }

  const closeMenu = e => {
    e.preventDefault()
    setShow(!show)
    setTimeout(() => {
      let el = document.getElementById("sticky-results")
      if (el != null) {
        el.classList.add("sticky-top")
      }
    }, 1000)
    setState(
      state.map(menu => {
        if (Array.isArray(menu.submenu)) {
          let p = menu.submenu.map(sub => ({ ...sub, ...{ isActive: false } }))
          return { ...menu, ...{ submenu: p }, ...{ isActive: false } }
        } else {
          return { ...menu, ...{ isActive: false } }
        }
      })
    )
  }

  // Search overlay
  function searchOverlay(e) {
    e.preventDefault()
    $("body")
      .addClass("modal-open")
      .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")

    const viewModal = document.getElementById("sticky-results")
    if (viewModal) {
      viewModal.style.zIndex = "4"
    }
  }
  // Search overlay

  let url = "/"

  const searchBuy = e => {
    var searcValue = query
      .split(", ")
      .join("-")
      .replace(/ /g, "-")
      .replace(/---/g, "-")
      .replace(/'/g, "")
      .toLowerCase()
    if (searcValue !== "") {
      url = "in-" + searcValue + "/"
    } else {
      url = "in-dubai/"
    }
    navigate("/properties-for-sale/" + url)
    // navigate('/properties/for-sale/'+url);
  }

  const searchRent = e => {
    var searcValue = query
      .split(", ")
      .join("-")
      .replace(/ /g, "-")
      .replace(/---/g, "-")
      .replace(/'/g, "")
      .toLowerCase()
    if (searcValue !== "") {
      url = "in-" + searcValue + "/"
    } else {
      url = "in-dubai/"
    }
    navigate("/properties-for-rent/" + url)
    // navigate('/properties/for-rent/'+url);
  }
  
  var officePhone = data?.glstrapi?.siteConfig?.Office_Phone.split(" ").join("")

  const pathName = location?.pathname?.split("/")[2];
  const mobile_Whatsapp = pathName?.includes('for-sale') || pathName?.includes('to-let') ? true : false;

  return (
    <header
      className={
        scroll
          ? `header--fixed header ${props?.headerTwo ? "header-two" : ""}`
          : `header ${props?.headerTwo ? "header-two" : ""} ${props?.position}`
      }
    >
      <SearchOverlay />
      <Container fluid>
        <Row>
          <Col>
            <Nav>
              <Navbar expand="xl">
                <Navbar.Brand>
                  <Link to="/">
                    <img
                      className="logo normal-logo"
                      src={BrandLogo}
                      alt="Logo"
                    />
                    <img
                      className="logo white-logo"
                      src={BrandWhiteLogo}
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Collapse
                  className={
                    show
                      ? "d-flex navigation-wrap active justify-content-center"
                      : "d-flex navigation-wrap justify-content-center"
                  }
                >
                  <div className="mobile-header">
                    <a className="close-btn" onClick={e => closeMenu(e)}>
                      <i className="icon-close"></i>
                    </a>
                    <Link
                      to="/"
                      className="position-absolute burger-logo"
                      onClick={e => closeMenu(e)}
                    >
                      <img
                        className="logo"
                        src={BrandWhiteLogo}
                        alt="Logo - Espace"
                      />
                    </Link>
                  </div>
                  <Nav className="d-xl-flex d-none align-items-center mt-0 py-7">
                    <div className="navigation d-flex flex-row w-100">
                      <div className="menu-accordian mx-5 d-flex justify-content-start flex-column w-60">
                        {state.slice(0, 9).map((item, i) => (
                          <MenuData
                            key={i}
                            index={i}
                            handlerOpenMenu={handlerOpenMenu}
                            handlerOpenSubMenu={handlerOpenSubMenu}
                            item={item}
                            active={location.pathname.includes(item.url)}
                          />
                        ))}
                      </div>
                      <div className="menu-accordian mx-5 d-flex justify-content-start flex-column reduce-font w-40">
                        {state.slice(9, 13).map((item, i) => (
                          <MenuData
                            key={i}
                            index={item.index}
                            handlerOpenMenu={handlerOpenMenu}
                            handlerOpenSubMenu={handlerOpenSubMenu}
                            item={item}
                            active={location.pathname.includes(item.url)}
                          />
                        ))}
                      </div>
                    </div>
                  </Nav>
                  <Nav className="d-xl-none d-block">
                    <div className="navigation">
                      <div
                        className={`menu-accordian ${
                          isSafari ? "os-ios" : "os-default"
                        }`}
                      >
                        {state.map((item, i) => (
                          <MenuData
                            key={i}
                            index={item.index}
                            handlerOpenMenu={handlerOpenMenu}
                            handlerOpenSubMenu={handlerOpenSubMenu}
                            item={item}
                          />
                        ))}
                      </div>
                    </div>
                  </Nav>
                </Navbar.Collapse>
                <div className="nav-right ml-auto  d-flex d-md-none">
                  <a
                    className="btn-circle btn-circle-hide"
                    onClick={e => searchOverlay(e)}
                  >
                    <i className="icon-search"></i>
                  </a>
                  <a
                    href={`tel:${officePhone}`}
                    className="btn-circle"
                  >
                    <i className="icon-mobile"></i>
                  </a>
                  {mobile_Whatsapp !=true ?
                    <>
                      {data?.glstrapi?.siteConfig?.Office_Whatsapp && (
                        <a
                          href={`https://api.whatsapp.com/send?phone=${removePlus(
                            data?.glstrapi?.siteConfig?.Office_Whatsapp
                          )}`}
                          target="_blank"
                          className="btn-circle"
                        >
                          <i className="icon-whatsapp-green"></i>
                        </a>
                      )}
                    </> : ''
                  }
                  <a className="btn-circle" onClick={e => showMenu(e)}>
                    <i className="icon-menustrip"></i>
                  </a>
                </div>
                <div className="nav-right ml-auto  d-none d-md-flex">
                  <div
                    className="position-relative btn-circle btn-circle-hide"
                    onMouseEnter={() => {
                      let el = document.getElementById("sticky-results")
                      if (el != null) {
                        el.classList.remove("sticky-top")
                      }
                      setDropDown(true)
                    }}
                    onMouseLeave={() => {
                      let el = document.getElementById("sticky-results")
                      if (el != null) {
                        el.classList.add("sticky-top")
                      }
                      setDropDown(false)
                    }}
                  >
                    <Link
                      to="/properties-for-sale/in-dubai/"
                      className="btn-circle btn-circle-hide me-0"
                    >
                      <i className="icon-home"></i>
                      <span>Find a Property</span>
                    </Link>
                    {showDropDown && (
                      <div
                        className="popup-card"
                        onMouseLeave={() => setDropDown(false)}
                      >
                        <Card className="mt-5">
                          <Card.Body>
                            <Card.Title>
                              Let’s find your new home together
                            </Card.Title>
                            <Card.Text>
                              Find your dream home with us, we have a wide range
                              of property that suits your need.
                            </Card.Text>
                            {/*                                                        <div className="banner-form m-0 w-100 position-relative">
                                                            <SearchInputBox
                                                                onSelect={(val) => setQuery(val)}
                                                            />
                                                            <Button variant="primary" type="submit">
                                                                <i className="icon-location"></i>
                                                            </Button>
                                                        </div>*/}
                            <div className="d-flex flex-row mt-4">
                              <button
                                className="btn subscribe-btn me-2"
                                onClick={searchBuy}
                              >
                                For Sale
                              </button>
                              <button
                                className="btn subscribe-btn ms-2"
                                onClick={searchRent}
                              >
                                For Rent
                              </button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </div>
                  <Link to="/property-valuation/" className="btn-circle">
                    <i className="icon-valuation"></i>Valuation
                  </Link>
                  <a
                    className="btn-circle d-none d-lg-flex"
                    onClick={searchOverlay}
                  >
                    <i className="icon-search-red"></i>Search
                  </a>
                  <a className="btn-circle" onClick={e => showMenu(e)}>
                    <i className="icon-menustrip-red"></i>Menu
                  </a>
                </div>
              </Navbar>
            </Nav>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header
