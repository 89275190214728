import { pickBy, identity, isArray, get, isEmpty, isString } from "lodash"
import qs from "qs";
import _ from "lodash";

const INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME
const SEARCH_BASE_URL = "properties"
const defaultArea = "dubai"

export const ACTION_TYPES = {
  sales: " for sale in ",
  lettings: " to rent in ",
}

export const ACTION_DESC_TYPES = {
  sales: " for sale ",
  lettings: " to rent ",
}

export const getPriceStr = (minPrice, maxPrice) => {
  let priceStr = ""
  if (minPrice && maxPrice) {
    priceStr =
      " between " +
      `AED` +
      addCommas(minPrice) +
      " and " +
      `AED` +
      addCommas(maxPrice)
  } else if (maxPrice) {
    priceStr = " under " + `AED` + addCommas(maxPrice)
  } else if (minPrice) {
    priceStr = " over " + `AED` + addCommas(minPrice)
  }
  return priceStr
}

function addCommas(num) {
  var str = num.toString().split(".")
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  return str.join(".")
}

export const toTitleCase = text => {
  let _text = text || ""
  if (_text) {
    _text = _text.trim().split(" ")
    if (_text.length > 0) {
      _text = _text
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(" ")
    }
  }
  return _text
}

const getSearchTypeStr = search_type => {
  let searchTypeStr = "for-sale"
  if (search_type === "lettings") searchTypeStr = "for-rent"

  return searchTypeStr
}

export const createSearchUrlFromParams = params => {
  let pricePath = "",
    bedroomPath = "",
    pagePath = "",
    propertyPath = "",
    sortPath = "",
    areaPath = "",
    projectPath = "",
    developerPath = "",
    layoutPath = "",
    search_typePath = "",
    featuresPath = ""

  // params.areas = params?.areas || defaultArea;
  // if (params?.areas) {

  if (isArray(params?.project)) {
    if (params.project.length > 0)
      projectPath = `project-${params.project.join("-and-")}/`
  } else {
    if (params?.project)
      projectPath = `project-${params.project.replace(" ", "-")}/`
  }
  projectPath = projectPath.toLowerCase().replaceAll(" ", "-")

  // developer
  if (!isEmpty(params?.developer)) {
    developerPath = `developer-${params?.developer
      .toLowerCase()
      .replaceAll(" ", "-")}/`
  }

  if (isArray(params?.areas)) {
    if (params.areas.length > 0) areaPath = `in-${params.areas.join("-and-")}/`
  } else {
    if (params?.areas) areaPath = `in-${params.areas.replace(" ", "-")}/`
  }

  if (!areaPath) areaPath = `in-${defaultArea}/`

  // }
  
  if (params?.building && !params?.building.startsWith("-")) {
    propertyPath = `type-${params.building.toLowerCase()}/`
  }
  if (params?.bedroom) {
    if (params?.bedroom == 0) bedroomPath = `studio/`
    else bedroomPath = `${params?.bedroom}-and-more-bedrooms/`
  }

  if (params.minPrice && params.maxPrice) {
    pricePath = `between-${params.minPrice}-and-${params.maxPrice}/`
  } else if (params.maxPrice) {
    pricePath = `under-${params.maxPrice}/`
  } else if (params.minPrice) {
    pricePath = `over-${params.minPrice}/`
  }

  // features
  if (params.accomadationSummary) {
    let accomadationSummary = params.accomadationSummary
    if (!isEmpty(accomadationSummary)) {
      if (isArray(accomadationSummary)) {
        featuresPath = `with-${accomadationSummary
          .join("-and-")
          .toLowerCase()}/`
      } else {
        featuresPath = `with-${accomadationSummary.toLowerCase()}/`
      }
      featuresPath = featuresPath.replaceAll(" ", "-")
    }
  }

  layoutPath = `${params?.searchBaseUrl}`

  if (params?.searchBaseUrl) {
    layoutPath = `${params?.searchBaseUrl}/`
  } else {
    layoutPath = SEARCH_BASE_URL
    if (params.search_type) {
      let searchTypeStr = "for-sale"
      if (params.search_type === "lettings") searchTypeStr = "for-rent"
      search_typePath = `-${searchTypeStr}`
    }
    if (params.layout) layoutPath += `-${params.layout}`
    layoutPath = `/${layoutPath}${search_typePath}/`
  }
  // Sort path
  if (params.sortBy) {
    if (params.sortBy === INDEX_NAME + "_price_asc") {
      sortPath = "sortby-price-asc/"
    }
    if (params.sortBy === INDEX_NAME + "_price_desc") {
      sortPath = "sortby-price-desc/"
    }
    if (params.sortBy === INDEX_NAME) {
      sortPath = ""
    }
  }

  let myUrl = `${areaPath}${propertyPath}${projectPath}${developerPath}${bedroomPath}${pricePath}${featuresPath}${sortPath}${pagePath}`

  return `${layoutPath}${myUrl}`
}

export const urlToSearchState = (
  location,
  search_type,
  layout,
  searchBaseUrl,
  parseUrl = false,
  multiArea = true
) => {
  let query = [`search_type=${search_type}`]
  let areaVal,
    projectVal,
    featureVal,
    minpriceVal,
    maxpriceVal,
    pageVal,
    buildingVal,
    developerVal,
    sortVal,
    sortVal_filt
  let bedVal = 10
  if (location) {
    let pathUri_main =
      typeof window !== "undefined" &&
      window.location.pathname.split(`${searchBaseUrl}`)

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")
      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Properties type
        if (pathUri[vi].indexOf("type-") >= 0) {
          buildingVal = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0 && pathUri[vi].indexOf("in-") <= 1) {
          let areaStr = pathUri[vi]
          areaVal = areaStr.replace("in-", "")
          if (areaVal === defaultArea) areaVal = ""
          if (multiArea) {
            areaVal = areaVal.split("-and-")
          }
        }

        // Project
        if (pathUri[vi].indexOf("project-") >= 0) {
          let projectStr = pathUri[vi]
          projectVal = projectStr.replace("project-", "")
          if (multiArea) {
            projectVal = projectVal.split("-and-")
          }
        }

        // developer type
        if (pathUri[vi].indexOf("developer-") >= 0) {
          developerVal = pathUri[vi].replace("developer-", "")
        }

        // feature
        if (pathUri[vi].indexOf("with-") >= 0 && pathUri[vi].indexOf("with-") <= 1) {
          let featureStr = pathUri[vi]
          featureVal = featureStr.replace("with-", "")
          if (multiArea) {
            featureVal = featureVal.split("-and-")
          }
          if (isString(featureVal)) {
            featureVal = [featureVal]
          }
          featureVal = featureVal.map(a => a.replaceAll("-", " "))
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
              sortVal = `${INDEX_NAME}_price_asc`
            } else if (sortVal_filt === "price-desc") {
                sortVal = `${INDEX_NAME}_price_desc`
            } else {
                sortVal = `${INDEX_NAME}`
            }
          
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  if (parseUrl) {
    // If no area send default to set default loaction
    // We could send defaut location
    // if (!areaVal || areaVal === "undefined") {
    //     areaVal = DEFAULT_LOCATION
    // }

    const params = {
      search_type: search_type,
      areas: areaVal,
      project: projectVal,
      accomadationSummary: featureVal,
      bedroom: bedVal < 10 ? bedVal : "",
      building: buildingVal,
      developer: developerVal,
      minPrice: minpriceVal,
      maxPrice: maxpriceVal,
      sortBy: sortVal_filt,
      page: pageVal,
      layout: layout.replace(/\/$/, ""),
    }
    return pickBy(params, identity)
  }

  // Properties type
  if (buildingVal) {
    query.push(`menu[building]=${buildingVal}`)
  }

  if (developerVal) {
    query.push(`menu[developer]=${developerVal.replaceAll("-", " ")}`)
  }

  // Area
  if (areaVal) {
    if (isArray(areaVal)) {
      areaVal.map(area => {
        if (searchBaseUrl.match(/off-plan|off-plan-developments/))
          query.push(`menu[search_areas]=${area.toLowerCase()}`)
        else query.push(`search_areas=${area.toLowerCase()}`)
      })
    } else {
      areaVal = areaVal.toLowerCase()
      query.push(`query=${areaVal}`)
    }
  }

  // Projects
  if (projectVal) {
    if (isArray(projectVal)) {
      projectVal.map(p => {
        query.push(`project=${p.toLowerCase().replaceAll("-", " ")}`)
      })
    } else {
      projectVal = projectVal.toLowerCase().replaceAll("-", " ")
      query.push(`project=${projectVal}`)
    }
  }

  // features
  if (featureVal) {
    if (isArray(featureVal)) {
      featureVal.map(feature => {
        query.push(`accomadation_summary=${feature.toLowerCase()}`)
      })
    } else {
      featureVal = featureVal.toLowerCase()
      query.push(`accomadation_summary=${featureVal}`)
    }
  }

  // Price
  if (minpriceVal) {
    query.push(`range[price][min]=${minpriceVal}`)
  }

  if (maxpriceVal) {
    query.push(`range[price][max]=${maxpriceVal}`)
  }

  // Bedrooms
  if (bedVal == 0) {
    query.push(`range[bedroom][min]=0`);
    query.push(`range[bedroom][max]=0`);
    if(!buildingVal) {
        query.push(`menu[building]=-land`)
    }
  } else if (bedVal < 10) {
    query.push(`range[bedroom][min]=${bedVal}`)
  }

  // Page
  if (pageVal) {
    query.push(`page=${pageVal}`)
  }

  // Sort by
  if (sortVal) {
    query.push(`sortBy=${sortVal}`)
  }
  query = query.join("&");
  return qs.parse(query)
}

export const createURL = state => {
  let myarea = state.menu

  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = ""
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertyPath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      areaPath =
        "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    } else {
        areaPath = "in-dubai/"
    }
  } else {
    areaPath = "in-dubai/"
  }

  const queryParameters = {}

  // Property path
  if (state.menu) {
    if (myarea["building"]) {
      propertyPath = "type-" + myarea["building"].replace(/ /g, "-") + "/"
    }
  }
  // Property path

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (
      state.range.hasOwnProperty("bedroom") &&
      (state.range.bedroom.max == 0 && state.range.bedroom.min == 0)
    ) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath =
          "between-" +
          state.range.price.min +
          "-and-" +
          state.range.price.max +
          "/" //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + "/" //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + "/" //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === INDEX_NAME + "_price_asc") {
      sortPath = "sortby-price-asc/"
    }
    if (state.sortBy === INDEX_NAME + "_price_desc") {
      sortPath = "sortby-price-desc/"
    }
    if (state.sortBy === INDEX_NAME) {
      sortPath = ""
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${state.searchBaseUrl}/${areaPath}${propertyPath}${bedroomPath}${pricePath}${queryString}${sortPath}${pagePath}`
  
//   if ("/" === myUrl.substr(myUrl.length - 1))
//     myUrl = myUrl.substr(0, myUrl.length - 1)

  return `${myUrl}`
}

export const searchStateToUrl = searchState =>
  searchState ? `${createURL(searchState)}` : ""

export const parseSearchUrl = (state, layout) => {
  let sortBy = "",
    page = ""

  if (state.sortBy) {
    if (state.sortBy === `${INDEX_NAME}_bedroom_asc`) sortBy = "bedroom-asc"
    else if (state.sortBy === `${INDEX_NAME}_bedroom_desc`)
      sortBy = "bedroom-desc"
    else if (state.sortBy === `${INDEX_NAME}_price_asc`) sortBy = "price-asc"
  }

  if (state.page && state.page !== 1) {
    page = state.page
  }

  const params = {
    search_type: state?.search_type,
    layout: layout,
    areas:
      state?.search_areas ||
      get(state, "menu.search_areas", "").toString() ||
      state?.query,
    accomadationSummary: get(state, "accomadation_summary", ""),
    bedroom: get(state, "range.bedroom.min", "").toString(),
    building: get(state, "menu.building", "").toString(),
    minPrice: get(state, "range.price.min", "").toString(),
    maxPrice: get(state, "range.price.max", "").toString(),
    sortBy: sortBy,
    page: page,
  }

  return pickBy(params, identity)
}

export const propertyH1 = (params, areasArr = []) => {
  const {
    areas,
    search_areas,
    bedroom,
    search_type,
    building,
    minPrice,
    maxPrice,
    extra,
  } = params;

  let h1 = ""
  let buildingStr = "Properties "

  if (bedroom) {
    let bedPlus = extra?.bedPlus ? "+" : "+";
    if(bedroom == 0) {
        h1 += " Studio "
    } else {
        h1 += bedroom + bedPlus + " Bedroom "
    }
  }
  if(building && !building.startsWith("-")) {
      if (building) {
        buildingStr = ` ${_.upperFirst(building).replace("-", " ")}`
        console.log( building)
        if(!["land", "duplex"].includes(building)) {
            buildingStr = ` ${_.upperFirst(building).replace("-", " ")}s`
        }
      } else {
        buildingStr = ` Property `
        if (params?.offPlan) buildingStr = ` Off Plan Property `
      }
  }
  if(!bedroom) {
      h1 += toTitleCase(" " + buildingStr)
    } else {
      h1 += " " + buildingStr.toLowerCase();
  }

  if (!isEmpty(search_type)) {
    h1 += ACTION_TYPES[search_type]
  }

  let areaStr = []
  if (
    (areasArr && areasArr.length === 0) ||
    !isEmpty(areas) ||
    !isEmpty(search_areas)
  ) {
    let _areas = areas || search_areas
    if (isEmpty(_areas)) _areas = toTitleCase(defaultArea)
    if (typeof _areas === "string" && _areas.match(/-/)) _areas = toTitleCase(_areas.replace("-", " "))
    areaStr = [_areas]
  }

  if (areasArr.length > 0) {
    areaStr = []
    areasArr.map(a => {
      areaStr.push(a.name)
    })
  }
  h1 += areaStr.join(", ")

  h1 += getPriceStr(minPrice, maxPrice)

  if (params.accomadationSummary) {
    let accomadationSummary = params.accomadationSummary
    if (isString(accomadationSummary))
      accomadationSummary = [accomadationSummary]
    accomadationSummary = accomadationSummary.join(", ")
    // replace  last comma with and
    accomadationSummary = accomadationSummary.replace(
      /,([^,]*)$/,
      " and" + "$1"
    )
    h1 += ` with ${accomadationSummary}`
  }

  return h1
}
